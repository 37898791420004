import React from 'react';

import '@/assets/scss/racket-rampage.scss';
import { withDashboardLayout } from '@/components/layout/dashboard-layout';
import FiltersLayout from '@/components/layout/filters-layout';
import NavPills from '@/features/racket-rampage/nav-pills';
import RewardEvent from '@/features/racket-rampage/reward-event';
import Store from '@/features/store';

const RRStore = () => {
  return (
    <>
      <FiltersLayout className="racket-rampage">
        <>
          <RewardEvent />
          <NavPills app="marketplace" />
          {/* store page listing should come here  */}
          <Store app="racket-rampage" />
        </>
      </FiltersLayout>
    </>
  );
};

export default withDashboardLayout(RRStore);

export function Head() {
  return <title>Superchamps Wallet | Racket Rampage</title>;
}
