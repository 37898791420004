import React, { FC } from 'react';

import StoreList from './list';

export interface IStore {
  app: string;
}

const Store: FC<IStore> = ({ app }) => {
  return <StoreList />;
};

export default Store;
